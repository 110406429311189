import { Injectable } from '@angular/core';
import { SpokeRequestSimpleDto } from '../spoke-request-simple.model';
import { Observable, throwError } from 'rxjs';
import { BaseApiService } from '../../../../../shared/services/_base-api.service';
import { NpsStore } from '../../../../services/nps.store';
import { SpokeRequestCompleteDto } from '../spoke-request-complete.model';
import { SpokeReplicaCompleteDto } from '../spoke-replica-complete.model';
import { SpokeRequestInitDto } from '../spoke-request-init.model';
import { SpokeRequestInitResponseDto } from '../responses/spoke-request-init-response.model';
import { SpokeRequestApprovalDto } from '../spoke-request-approval.model';
import { SpokeRequestApprovalResponseDto } from '../responses/spoke-request-approval-response.model';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { NpsConfigurationApiUrl } from '../../../shared/api/configuration.service';

export const NpsProjectSpokesUrl = {
  GpSpokeCreateRequest: (projectId: string) => `/api/projects/profiles/${projectId}/spokes`,
  OpSpokeCreateReplication: () => `/api/operations/spokes`,
  OpSpokeRequestsGetList: () => `/api/operations/requests/spokes`,
  OpSpokeRequestGetByMappingId: (mappingId: string) => `/api/operations/requests/spokes/${mappingId}`,
  OpSpokeRequestApprove: (mappingId: string) => `/api/operations/requests/spokes/${mappingId}/approve`,
  OpSpokeRequestValidateVirtualNetworkCidr: () => `/api/operations/requests/spokes/validate/virtual-network-cidr`,
  OpSpokeRequestDeployGlobalDns: (mappingId: string) => `/api/operations/requests/spokes/${mappingId}/arm/globaldns/deploy`,
}

@Injectable({
  providedIn: 'root'
})
export class SpokeRequestsService extends BaseApiService {

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  getValidators(method: keyof typeof NpsProjectSpokesUrl, part: string = '') {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method, part });
  }

  SpokeCreateRequest(spokeRequest: SpokeRequestInitDto) : Observable<SpokeRequestInitResponseDto> {
    if (spokeRequest?.projectId)
      return super.post<SpokeRequestInitDto, SpokeRequestInitResponseDto>(NpsProjectSpokesUrl.GpSpokeCreateRequest(spokeRequest.projectId), spokeRequest);
    else {
      const error: any = new Error(`Spoke Request body was incomplete, request creation is cancelled`);
      return throwError(() => error);
    }
  }

  SpokeApproveRequest(mappingId: string, spokeRequest: SpokeRequestApprovalDto) : Observable<SpokeRequestApprovalResponseDto> {
    if (mappingId && spokeRequest)
      return super.put<SpokeRequestApprovalDto, SpokeRequestApprovalResponseDto>(NpsProjectSpokesUrl.OpSpokeRequestApprove(mappingId), spokeRequest);
    else {
      const error: any = new Error(`Provided details were incomplete, request status update is cancelled`);
      return throwError(() => error);
    }
  }

  spokeCreateReplication(spokeReplica: SpokeReplicaCompleteDto) : Observable<SpokeRequestCompleteDto> {
    return super.post<SpokeReplicaCompleteDto, SpokeRequestCompleteDto>(NpsProjectSpokesUrl.OpSpokeCreateReplication(), spokeReplica);
  }

  spokeRequestsGetList(projectId: string = '', createdBy: string = '', status: string = '') : Observable<SpokeRequestSimpleDto[]> {
    return super.get<SpokeRequestSimpleDto[]>(NpsProjectSpokesUrl.OpSpokeRequestsGetList(), {projectId, createdBy, status});
  }

  spokeRequestsGetById(mappingId: string) : Observable<SpokeRequestCompleteDto> {
    return super.get<SpokeRequestCompleteDto>(NpsProjectSpokesUrl.OpSpokeRequestGetByMappingId(mappingId));
  }

  spokeRequestValidateVirtualNetworkCidr(cidr: string, spokeId?: string): Observable<string[]> {
    return super.post(NpsProjectSpokesUrl.OpSpokeRequestValidateVirtualNetworkCidr(), { virtualNetworkCIDR: cidr, spokeId });
  }

  spokeRequestDeployGlobalDns(mappingId: string): Observable<any> {
    return super.post(NpsProjectSpokesUrl.OpSpokeRequestDeployGlobalDns(mappingId), { });
  }
}
